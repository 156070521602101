import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { Observable } from 'rxjs';

import { RoundButtonModule } from '../round-button/round-button.component';

export interface MenuItem {
  label: string;
  action?: (_?: any) => void;
  isShown?: Observable<boolean>;
  danger?: boolean;
  icon?: string;
  disabled?: boolean;
}

@Component({
  selector: 'app-dropdown-menu',
  template: `
    <div
      class="dropdown__trigger"
      nz-dropdown
      [nzDropdownMenu]="menu"
      [nzTrigger]="'click'"
      [nzPlacement]="'topRight'"
      [(nzVisible)]="visible"
      (nzVisibleChange)="visibleChanged.emit($event)"
      (click)="onButtonClick($event)"
    >
      <ng-content></ng-content>
    </div>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul
        class="dropdown__menu"
        nz-menu
        nzSelectable
      >
        <ng-container *ngFor="let item of menuItems">
          <li
            *ngIf="(item?.isShown | async) !== false"
            class="menu-item"
            nz-menu-item
            [class.menu-item--danger]="item?.danger"
            [nzDisabled]="item.disabled"
            (click)="onActionClick(item)"
          >
            <span class="menu-item__content">
              <ng-container *ngIf="iconPosition === 'before'">
              <i
                *ngIf="item.icon"
                class="menu-icon menu-icon--before"
                nz-icon
                [nzType]="item.icon"
              ></i>
            </ng-container>
            <span>{{ item.label }}</span>
            <ng-container *ngIf="iconPosition === 'after'">
              <i
                *ngIf="item.icon"
                class="menu-icon menu-icon--after"
                nz-icon
                [nzType]="item.icon"
              ></i>
            </ng-container>
            </span>
          </li>
        </ng-container>
      </ul>
    </nz-dropdown-menu>
  `,
  styleUrls: ['./dropdown-menu.component.less']
})
export class DropDownMenuComponent {
  @Input() public menuItems: MenuItem[] = [];
  @Input() public iconPosition: 'before' | 'after' = 'after';

  @Output() public visibleChanged = new EventEmitter<boolean>();
  @Output() public menuItemSelected = new EventEmitter<MenuItem>();

  public visible = false;

  public onButtonClick(ev: MouseEvent) {
    ev.stopPropagation();
    ev.preventDefault();
  }

  public onActionClick(item: MenuItem) {
    if (item.disabled) {
      return;
    }
    if (item?.action) {
      item.action();
    }
    this.menuItemSelected.emit(item);
  }
}

@NgModule({
  declarations: [DropDownMenuComponent],
  exports: [DropDownMenuComponent],
  imports: [
    RoundButtonModule, //
    NzIconModule,
    NzDropDownModule,
    CommonModule
  ]
})
export class DropDownMenuModule {}
